import React,{Component} from 'react';
import {GallerySection,GalleryOuterWrapper,HeadingLayout,Heading,Border,GalleryWrapper,ImageHolder
} from './gallery.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';

class Gallery extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isopenDialogBox: false,
            lightBoxImages:[],
            photoIndex: 0,
        }
    }

    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return(
            <GallerySection id="galleryContainer">
                <Container>
                    <GalleryOuterWrapper>
                        <HeadingLayout>
                            <Heading>
                            {this.props.hospitalJson.Gallery.Heading} 
                            </Heading>
                            <Border src={this.props.hospitalJson.Gallery.Border}  alt=""/>
                        </HeadingLayout>
                        <GalleryWrapper>
                            <Row>
                            {
                                this.props.hospitalJson.Gallery.GalleryData.map((galleryItem, idx)=>{
                                    return <Col md={4}>
                                        <ImageHolder onClick={this.openDialogBox.bind(this,this.props.hospitalJson.Gallery.GalleryData,idx)}>
                                            <GatsImg 
                                                fluid={galleryItem.Img.childImageSharp.fluid} 
                                                alt=""
                                                className="galleryImg"
                                            />
                                        </ImageHolder>
                                    </Col>
                                })
                            }

                                
                            </Row>
                        </GalleryWrapper>
                    </GalleryOuterWrapper>
                </Container>
            </GallerySection>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                hospitalJson{
                    Gallery{
                    Heading
                    Border
                    GalleryData{
                            Img{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Gallery 
                hospitalJson={data.hospitalJson}
                {...props}
            />
        )}
    />
    )
