import React from 'react';
import {AboutSection,CustomRow,TextLayout,HeadingLayout,Heading,Border,Description1,
    List,ListItem,CheckCircleIcon,ListText,ContactUsBtn,ImageHolder
} from './about.style';
import {Container,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    About{
                       Heading
                       Border
                       Description1
                       ListText1
                       ListText2
                       ContactUsBtn
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
        return (
        <AboutSection id="aboutContainer">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg 
                                fluid={JSONData.hospitalJson.About.Img.childImageSharp.fluid} 
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <HeadingLayout>
                                <Heading>
                                {JSONData.hospitalJson.About.Heading} 
                                </Heading>
                                <Border src={JSONData.hospitalJson.About.Border} alt=""/>
                            </HeadingLayout>
                            <Description1>
                            {JSONData.hospitalJson.About.Description1} 
                            </Description1>
                            <List>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <ListText>{JSONData.hospitalJson.About.ListText1}</ListText>
                                </ListItem>
                                <ListItem>
                                    <CheckCircleIcon />
                                    <ListText>{JSONData.hospitalJson.About.ListText2}</ListText>
                                </ListItem>
                            </List>
                            <ContactUsBtn>
                            {JSONData.hospitalJson.About.ContactUsBtn} 
                            </ContactUsBtn>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;