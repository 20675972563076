import styled from 'styled-components';
import {Commonpara,SectionHeading,Commonh5} from '../Common/common.style';
import {device} from '../Common/device';

export const SpecialitiesSection = styled.section`
    
`;

export const SpecialitiesWrapper = styled.div`
    padding:100px 0px;
    display:flex;
    align-items:center;
    border-bottom:1px solid #ebebeb;

    @media ${device.laptopM}{
        padding:100px 0px 70px;
    }
    @media ${device.laptop}{
        padding:100px 0px 70px;
    }
    @media ${device.tablet}{
        padding:80px 10px 50px;
    }
`;

export const SpecialitiesLeft = styled.div`
    width:65%;
    margin-right:15px;

    @media ${device.laptop}{
        width:100%;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`

`;

export const Description = styled(Commonpara)`

`;

export const SpecialityLayout = styled.div`
    display:flex;
    align-items:flex-start;
    margin-bottom:30px;
`;

export const SpecialityTextLayout = styled.div`
    margin-left:15px;
`;

export const Icon = styled.img`
    width:50px;
    flex-shrink:0;
`;

export const SpecialityHeading = styled(Commonh5)`
    text-align:left;
    line-height:1;
    margin-bottom:10px;
`;

export const SpecialityDescription = styled(Commonpara)`
    text-align:left;
    margin-bottom:0px;
`;

export const SpecialitiesRight = styled.div`
    width:35%;
    margin-left:15px;

    @media ${device.laptop}{
        display:none;
    }
`;

export const ImageHolder = styled.div`
    
`;
