import React from 'react';
import {EmergencySection,EmergencyLayout,EmergencyText,EmergencyNumber,CallNowBtn
} from './emergency.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Emergency = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Emergency{
                        EmergencyHeading
                        EmergencyContact
                        EmergencyBtn
                    }
                }
            }
    `);
        return (
        <EmergencySection id="emergencyContainer">
            <Container>
                <Row>
                    <Col md={6}>
                        <EmergencyLayout>
                            <EmergencyText>
                            {JSONData.hospitalJson.Emergency.EmergencyHeading} 
                            </EmergencyText>
                            <EmergencyNumber>
                            {JSONData.hospitalJson.Emergency.EmergencyContact} 
                            </EmergencyNumber>
                            <CallNowBtn>
                            {JSONData.hospitalJson.Emergency.EmergencyBtn} 
                            </CallNowBtn>
                        </EmergencyLayout>
                    </Col>
                </Row>
            </Container>
        </EmergencySection>
    );
}
export default Emergency;