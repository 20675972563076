import React,{Component} from 'react';
import {OurSpecialistsSection,HeadingLayout,Heading,Border,SliderOuterLayout,
    LeftArrowLayout,LeftArrow,
    SpecialistOuterWrapper,SliderInnerLayout,TeamLayout,SpecialistName,Department,
    RightArrowLayout,RightArrow
} from './ourspecialists.style';
import {Container} from '@bootstrap-styled/v4';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class OurSpecialists extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        var settings = {
            dots: false,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                    }
                },
                {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
                }
            ]
        };

        return (
            <OurSpecialistsSection id="ourSpecialistsContainer">
                <HeadingLayout>
                    <Heading>
                    {this.props.hospitalJson.OurSpecialists.Heading} 
                    </Heading>
                    <Border src={this.props.hospitalJson.OurSpecialists.Border}  alt=""/>
                </HeadingLayout>
                <SliderOuterLayout>
                    <LeftArrowLayout>
                        <LeftArrow onClick={this.previous}/>
                    </LeftArrowLayout>

                    <Container>
                        <SpecialistOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    this.props.hospitalJson.OurSpecialists.Slider.map((team,idx)=>{
                                        return <SliderInnerLayout>
                                            <TeamLayout>
                                                <GatsImg 
                                                    fluid={team.TeamProPic.childImageSharp.fluid} 
                                                    className="TeamProPic"
                                                    alt=""
                                                />
                                                <SpecialistName>
                                                {team.SpecialistName}
                                                </SpecialistName>
                                                <Department>
                                                {team.Department}
                                                </Department>
                                            </TeamLayout>
                                        </SliderInnerLayout>
                                    })
                                }
                            </Slider>
                        </SpecialistOuterWrapper>
                    </Container>
                    <RightArrowLayout>
                        <RightArrow onClick={this.next}/>
                    </RightArrowLayout>
                </SliderOuterLayout>
            </OurSpecialistsSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                hospitalJson{
                    OurSpecialists{
                        Heading
                        Border
                        Slider{
                            TeamProPic{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            SpecialistName
                            Department
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <OurSpecialists hospitalJson={data.hospitalJson}/>
        )}
    />
  )