import React from 'react';
import {StatsSection,StatsLayout,StatsCount,StatsDetails
} from './stats.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Stats = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Stats{
                        StatsData{
                            StatsCount
                            StatsDetails
                        }
                    }
                }
            }
    `);
        return (
        <StatsSection id="StatsContainer">
            <Container>
                <Row>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <StatsCount>
                            {JSONData.hospitalJson.Stats.StatsData[0].StatsCount} 
                            </StatsCount>
                            <StatsDetails>
                            {JSONData.hospitalJson.Stats.StatsData[0].StatsDetails} 
                            </StatsDetails>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <StatsCount>
                            {JSONData.hospitalJson.Stats.StatsData[1].StatsCount} 
                            </StatsCount>
                            <StatsDetails>
                            {JSONData.hospitalJson.Stats.StatsData[1].StatsDetails} 
                            </StatsDetails>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <StatsCount>
                            {JSONData.hospitalJson.Stats.StatsData[2].StatsCount} 
                            </StatsCount>
                            <StatsDetails>
                            {JSONData.hospitalJson.Stats.StatsData[2].StatsDetails} 
                            </StatsDetails>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <StatsCount>
                            {JSONData.hospitalJson.Stats.StatsData[3].StatsCount} 
                            </StatsCount>
                            <StatsDetails>
                            {JSONData.hospitalJson.Stats.StatsData[3].StatsDetails} 
                            </StatsDetails>
                        </StatsLayout>
                    </Col>
                </Row>

             
            </Container>
        </StatsSection>
    );
}
export default Stats;