import React,{Component} from 'react';
import {TestimonialsSection,HeadingLayout,Heading,Border,SliderOuterLayout,LeftArrowLayout,LeftArrow,
    TestimonialsOuterWrapper,SliderInnerLayout,TestimonialLayout,QuotesTop,Testimonial,
    QuotesHolder,QuotesBottom,AuthorLayout,TextLayout,Name,Designation,
    RightArrowLayout,RightArrow
} from './testimonials.style';
import {Container} from '@bootstrap-styled/v4';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class TestimonialsPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        var settings = {
            dots: false,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <TestimonialsSection id="testimonialsContainer">
                <HeadingLayout>
                    <Heading>
                    {this.props.hospitalJson.Testimonials.Heading} 
                    </Heading>
                    <Border src={this.props.hospitalJson.Testimonials.Border}  alt=""/>
                </HeadingLayout>
                
                <SliderOuterLayout>
                    <LeftArrowLayout>
                        <LeftArrow onClick={this.previous}/>
                    </LeftArrowLayout>
                    <Container>
                        <TestimonialsOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    this.props.hospitalJson.Testimonials.Slider.map((testimonial, idx)=>{
                                        return <SliderInnerLayout>
                                            <TestimonialLayout>
                                                <QuotesTop />
                                                <Testimonial>
                                                {testimonial.Testimonial}
                                                </Testimonial>
                                                <QuotesHolder>
                                                    <QuotesBottom />
                                                </QuotesHolder>
                                                <AuthorLayout>
                                                    <GatsImg 
                                                        fluid={testimonial.ProPic.childImageSharp.fluid} 
                                                        className="ProPic"
                                                        alt=""
                                                    />
                                                    <TextLayout>
                                                        <Name>
                                                        {testimonial.Name}
                                                        </Name>
                                                        <Designation>
                                                        {testimonial.Designation}
                                                        </Designation>
                                                    </TextLayout>
                                                </AuthorLayout>
                                            </TestimonialLayout>
                                        </SliderInnerLayout>
                                    })
                                }
                            </Slider>
                        </TestimonialsOuterWrapper>
                    </Container>

                    <RightArrowLayout>
                        <RightArrow onClick={this.next}/>
                    </RightArrowLayout>

                </SliderOuterLayout>
            </TestimonialsSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                hospitalJson{
                    Testimonials{
                        Heading
                        Border
                        Slider{
                            Testimonial
                            ProPic{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            Name
                            Designation
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <TestimonialsPage hospitalJson={data.hospitalJson}/>
        )}
    />
  )