import styled from 'styled-components';
import {Commonpara,Commonh4} from '../Common/common.style';
import {device} from '../Common/device';

export const ServicesSection = styled.section`
    
`;
export const FeaturesWrapper = styled.div`
    padding:100px 0px 70px;
    border-bottom:1px solid #ebebeb;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const FeaturesLayout = styled.div`
    background:${props => props.bg};
    padding:30px;
    margin-bottom:30px;
`;

export const Img = styled.img`
    height:60px;
    margin-bottom:20px;
`;

export const FeaturesHeading = styled(Commonh4)`
    color:#fff;
    text-align:left;
`;

export const Paragraph = styled(Commonpara)`
    color:#fff;
    margin-bottom:0px;
`;