import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterSection,FooterCol,FooterTitle,FooterPara,FooterLogo,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,FooterInner,
    FooterAddWrapper,FooterAddLeft,FooterAddImg,FooterAddRight,FooterAddText,
    FooterAnchorLayout,FooterSocialWrapper,FooterSocial,BottomFooterPara,UnorderedList,
    BottomFooterWrapper,BottomLink
} from './footer.style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query{
        hospitalJson {
            Footer {
                DelayConstant
                FooterCol1{
                    FooterLogo
                    FooterPara
                }
                FooterCol2{
                    FooterTitle
                    FooterPara
                    FooterAddWrapper{
                        FooterAddImg
                        FooterAddText
                    }
                }
                FooterCol3{
                    FooterUl{
                        FooterMenu
                        Href
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
            }
        }
    }
`);
    return (
        <div>
        <FooterSection>
            <FooterInner>
                <Container>
                    <Row>
                        <Col md="6">
                            <FooterCol>
                                <FooterLogo src={JSONData.hospitalJson.Footer.FooterCol1.FooterLogo} alt=""/>
                                <FooterPara>
                                {JSONData.hospitalJson.Footer.FooterCol1.FooterPara}
                                </FooterPara>
                            </FooterCol>
                        </Col>
                        <Col md="3">
                            <FooterCol>
                                <FooterTitle>{JSONData.hospitalJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                                <FooterPara>
                                {JSONData.hospitalJson.Footer.FooterCol2.FooterPara}
                                </FooterPara>
                                {
                                JSONData.hospitalJson.Footer.FooterCol2.FooterAddWrapper.map((item,idx) => {
                                return <FooterAddWrapper>
                                        <FooterAddLeft>
                                            <FooterAddImg src={item.FooterAddImg} alt=""/>
                                        </FooterAddLeft>
                                        <FooterAddRight>
                                            <FooterAddText>{item.FooterAddText}</FooterAddText>
                                        </FooterAddRight>
                                    </FooterAddWrapper>
                                })
                            }
                            </FooterCol>
                        </Col>
                    </Row>
                </Container>
            </FooterInner>
            <Container>
                <Row>
                    <Col md="6"></Col>
                    <Col md="6">
                        <UnorderedList>
                        {
                            JSONData.hospitalJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                            return <FooterAnchorLayout>
                                <AnchorLink offset={55} href={item.Href}>
                                    {item.FooterMenu}
                                </AnchorLink>
                            </FooterAnchorLayout>
                            })
                        }
                        </UnorderedList>
                    </Col>
                </Row>
            </Container>
        </FooterSection>
        <BottomFooterWrapper>
            <Container>
                <Row>
                    <Col md="6">
                        <BottomFooterPara>
                            {JSONData.hospitalJson.Footer.BottomFooterPara}
                            <BottomLink rel="noreferrer" href={JSONData.hospitalJson.Footer.BottomFooterLink} target="_blank">
                            {JSONData.hospitalJson.Footer.BottomFooterName}
                            </BottomLink>
                        </BottomFooterPara>
                    </Col>
                    <Col md="6">
                        <FooterSocialWrapper>
                            <FooterSocial href="#" aria-label={"Fb Link"}>
                                <FbIcon/>
                            </FooterSocial>
                            <FooterSocial href="#" aria-label={"Twitter Link"}>
                                <TwitterIcon/>
                            </FooterSocial>
                            <FooterSocial href="#" aria-label={"Linkedin Link"}>
                                <LinkedinIcon/>
                            </FooterSocial>
                            <FooterSocial href="#" aria-label={"Instagram Link"}>
                                <InstaIcon/>
                            </FooterSocial>
                        </FooterSocialWrapper>
                    </Col>
                </Row>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;