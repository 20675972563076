import React from 'react';
import {ServicesSection,FeaturesWrapper,FeaturesLayout,Img,FeaturesHeading,Paragraph} from './services.style';
import {Row,Col,Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';

const Services = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Services{
                        ServicesData{
                            Img
                            FeaturesHeading
                            Paragraph
                        }
                    }
                }
            }
    `);
        return (
        <ServicesSection id="servicesContainer">
            <Container>
                <FeaturesWrapper>
                <Row>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#00a651"}>
                            <Img 
                                src={JSONData.hospitalJson.Services.ServicesData[0].Img} 
                                alt=""
                            />
                            <FeaturesHeading>
                                { JSONData.hospitalJson.Services.ServicesData[0].FeaturesHeading }
                            </FeaturesHeading>
                            <Paragraph>
                            { JSONData.hospitalJson.Services.ServicesData[0].Paragraph }
                            </Paragraph>
                        </FeaturesLayout>
                    </Col>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#1997b1"}>
                            <Img 
                                src={JSONData.hospitalJson.Services.ServicesData[1].Img} 
                                alt=""
                            />
                            <FeaturesHeading>
                                { JSONData.hospitalJson.Services.ServicesData[1].FeaturesHeading }
                            </FeaturesHeading>
                            <Paragraph>
                            { JSONData.hospitalJson.Services.ServicesData[1].Paragraph }
                            </Paragraph>
                        </FeaturesLayout>
                    </Col>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#1565c0"}>
                            <Img 
                                src={JSONData.hospitalJson.Services.ServicesData[2].Img} 
                                alt=""
                            />
                            <FeaturesHeading>
                                { JSONData.hospitalJson.Services.ServicesData[2].FeaturesHeading }
                            </FeaturesHeading>
                            <Paragraph>
                            { JSONData.hospitalJson.Services.ServicesData[2].Paragraph }
                            </Paragraph>
                        </FeaturesLayout>
                    </Col>
                </Row>
                </FeaturesWrapper>
            </Container>
        </ServicesSection>
    );
}

export default Services;