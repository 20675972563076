import React from 'react';
import {SpecialitiesSection,SpecialitiesWrapper,SpecialitiesLeft,HeadingLayout,Heading,Border,
    Description,SpecialityLayout,SpecialityTextLayout,Icon,SpecialityHeading,
    SpecialityDescription,SpecialitiesRight,
    ImageHolder
} from './specialities.style';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Specialities = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Specialities{
                        Heading
                        Border
                        Description
                        SpecialitiesData{
                            Icon
                            SpecialityHeading
                            SpecialityDescription
                       }
                       SpecialityRightImg{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
        return (
        <SpecialitiesSection id="specialitiesContainer">
            <Container>
                <SpecialitiesWrapper>
                    <SpecialitiesLeft>
                        <HeadingLayout>
                            <Heading>
                            {JSONData.hospitalJson.Specialities.Heading} 
                            </Heading>
                            <Border src={JSONData.hospitalJson.Specialities.Border}  alt=""/>
                        </HeadingLayout>
                        <Description>
                        {JSONData.hospitalJson.Specialities.Description} 
                        </Description>
                        <Row>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[0].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[0].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[0].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[1].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[1].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[1].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[2].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[2].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[2].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[3].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[3].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[3].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[4].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[4].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[4].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                            <Col md={6}>
                                <SpecialityLayout>
                                    <Icon src={JSONData.hospitalJson.Specialities.SpecialitiesData[5].Icon} alt=""/>
                                    <SpecialityTextLayout>
                                    <SpecialityHeading>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[5].SpecialityHeading} 
                                    </SpecialityHeading>
                                    <SpecialityDescription>
                                    {JSONData.hospitalJson.Specialities.SpecialitiesData[5].SpecialityDescription} 
                                    </SpecialityDescription>
                                    </SpecialityTextLayout>
                                </SpecialityLayout>
                            </Col>
                        </Row>
                    </SpecialitiesLeft>
                    <SpecialitiesRight>
                        <ImageHolder>
                            <GatsImg 
                                    fluid={JSONData.hospitalJson.Specialities.SpecialityRightImg.childImageSharp.fluid} 
                                    alt=""
                                />
                        </ImageHolder>
                    </SpecialitiesRight>
                </SpecialitiesWrapper>
            </Container>
        </SpecialitiesSection>
    );
}
export default Specialities;