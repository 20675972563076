import styled from 'styled-components';
import {Commonh5,Commonh1,Commonbtn} from '../Common/common.style';
import BackgroundImg from '../../../assets/hospital-images/small-banner.jpg'
import {device} from '../Common/device';

export const EmergencySection = styled.section`
    background-image: url(${BackgroundImg});
    padding:80px 0px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x: center;

    @media ${device.laptop}{
        background-position-x: 40%;
    }
    @media ${device.tablet}{
        padding:80px 10px;
        background-position-x: 50%;
    }
`;

export const EmergencyLayout = styled.div`

`;

export const EmergencyText = styled(Commonh5)`
    color:#fff;
    text-align:left;
    line-height:1;
`;

export const EmergencyNumber = styled(Commonh1)`
    color:#fff;
    line-height:1;

    @media ${device.tablet}{
        text-align:left;
    }
`;

export const CallNowBtn = styled(Commonbtn)`
    border:2px solid #fff;
    background:#ffffff33;
`;
