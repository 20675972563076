import styled from 'styled-components';
import {Commonh1,Commonh6} from '../Common/common.style';
import BackgroundImg from '../../../assets/hospital-images/parallax-banner.jpg'
import {device} from '../Common/device';

export const StatsSection = styled.section`
    background-image: url(${BackgroundImg});
    padding:100px 0px 70px;

    @media ${device.tablet}{
        padding:80px 10px 50px;
    }
    
`;

export const StatsLayout = styled.div`
    margin-bottom:30px;
`;

export const StatsCount = styled(Commonh1)`
    color:#fff;
    text-align:center;
    line-height:1;

    @media ${device.laptop} {
        line-height:1;
    }
    @media ${device.tablet} {
        line-height:1;
    }
    @media ${device.mobileXL} {
        line-height:1;
    }
`;

export const StatsDetails = styled(Commonh6)`
    color:#fff;
    line-height:1;
    margin-bottom:0px;
    text-align:center;
`;


